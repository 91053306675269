<template>
  <v-card
    width="100%"
    class="overflow-hidden"
  >
    <v-app-bar
      class="white pa-0"
      elevate-on-scroll
      dense
      height="60"
      extension-height="48"
      absolute
      scroll-target="#scrolling-person-add"
      style="z-index: 3;"
    >
      <!-- <template v-slot:extension>
                <v-tabs>
                    <v-tab>Tab 1</v-tab>
                    <v-tab>Tab 2</v-tab>
                    <v-tab>Tab 3</v-tab>
                </v-tabs>
            </template> -->

      <v-toolbar-title
        class="pl-4"
        data-cy="create_np_title"
      >
        {{ $t('person.details.title') }}
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        text
        @click.prevent="createPerson"
        class="mr-3"
        color="primary"
        data-cy="np_save_btn"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        {{ $t('person.details.buttons.save') }}
      </v-btn>
    </v-app-bar>
    <v-container
      fluid
      ref="form"
      id="scrolling-person-add"
      class="overflow-y-auto"
      style="height: calc(100vh - 66px - 46px - 108px - 36px + 48px);margin-top: 60px;"
      data-cy="np_add_container"
    >
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <!-- {{ $v.form.names.at_least_one_name }}
            {{ $v.form.$anyError }} -->
      <v-alert
        border="right"
        colored-border
        type="error"
        elevation="3"
        :value="$v.form.$anyError"
        transition="scroll-x-transition"
        data-cy="np_error_msg"
      >
        {{ !$v.form.names.at_least_one_name ? 'Need at least one name' : '' }}
      </v-alert>

      <!-- <v-row> -->
      <v-col cols="5">
        <v-text-field
          :label="$t('person.details.form.ref_id.label')"
          autocomplete="off"
          transition="scale-transition"
          hide-details
          dense
          v-model="form.ref_id"
          :data-cy="`ref_id`"
        />
      </v-col>
      <!-- </v-row> -->

      <v-card-title>{{ $t('person.details.tabs.names') }}</v-card-title>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-row no-gutters>
                <v-btn-toggle
                  v-model="name_form.lang"
                  color="primary"
                  class="mr-3"
                  dense
                >
                  <v-btn
                    :value="1"
                    data-cy="np_eng_name_btn"
                  >
                    {{ $t('person.details.form_name.lang.options.english') }}
                  </v-btn>

                  <v-btn
                    :value="2"
                    data-cy="np_chn_name_btn"
                  >
                    {{ $t('person.details.form_name.lang.options.chinese') }}
                  </v-btn>
                </v-btn-toggle>

                <v-btn-toggle
                  v-model="name_form.name_type"
                  color="primary"
                  dense
                >
                  <v-btn
                    :value="1"
                    data-cy="np_primary_name_btn"
                  >
                    {{ $t('person.details.form_name.name_type.options.primary') }}
                  </v-btn>

                  <v-btn
                    :value="2"
                    data-cy="np_alias_name_btn"
                  >
                    {{ $t('person.details.form_name.name_type.options.alias') }}
                  </v-btn>
                </v-btn-toggle>
              </v-row>

              <v-menu
                offset-x
                :value="like.name_form.is_show"
                :close-on-content-click="false"
                :close-on-click="false"
                :open-on-click="false"
              >
                <template v-slot:activator="{ attrs }">
                  <v-row>
                    <v-col>
                      <v-text-field
                        :label="$t('person.details.form_name.sur.label')"
                        autocomplete="off"
                        transition="scale-transition"
                        v-bind="{...attrs}"
                        v-model="name_form.sur"
                        @focus="toggleSimilarDropdown(like.name_form, true)"
                        @change="toggleSimilarDropdown(like.name_form, name_form.sur.length > 0)"
                        @blur="toggleSimilarDropdown(like.name_form, false)"
                        :error="$v.name_form.sur.$error || $v.name_form.$error || $v.form.names.$error"
                        :error-messages="($v.name_form.sur.$error || $v.name_form.$error || $v.form.names.$error) ?
                          (!$v.form.names.at_least_one_name ? 'Need at least one name' :
                            (!$v.name_form.sur.required ? $t('validate.required', { field: $t('person.details.form_name.sur.label') }) : (
                              !$v.name_form.duplicate_local ? 'Name is duplicated' : (
                                !$v.name_form.duplicate_db ? 'Name is duplicated in database' : (
                                  !$v.name_form.sur.chinese_words ? 'Chinese Name must contain Chinese words' : ''))))) : ''"
                        data-cy="np_sur_name_textfield"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :label="$t('person.details.form_name.other.label')"
                        autocomplete="off"
                        v-bind="{...attrs}"
                        v-model="name_form.other"
                        @focus="toggleSimilarDropdown(like.name_form, true)"
                        @change="toggleSimilarDropdown(like.name_form, name_form.other.length > 0)"
                        @blur="toggleSimilarDropdown(like.name_form, false)"
                        :error="$v.name_form.other.$error || $v.name_form.$error || $v.form.names.$error"
                        :error-messages="($v.name_form.other.$error || $v.name_form.$error || $v.form.names.$error) ?
                          (!$v.form.names.at_least_one_name ? 'Need at least one name' : (
                            !$v.name_form.other.required ? $t('validate.required', { field: $t('person.details.form_name.other.label') }) : (
                              !$v.name_form.duplicate_local ? 'Name is duplicated' : (
                                !$v.name_form.duplicate_db ? 'Name is duplicated in database' : (
                                  !$v.name_form.other.chinese_words ? 'Chinese Name must contain Chinese words' : ''))))) : ''"
                        data-cy="np_other_name_textfield"
                      />
                    </v-col>
                  </v-row>
                </template>
                <v-card :loading="!!name_similar_check_timeout">
                  <v-card-subtitle>{{ $t('person.details.form_name.dropdown.title') }}</v-card-subtitle>
                  <v-card-text v-if="like.name_form.options.length == 0">
                    {{ $t('person.details.form_name.dropdown.no_records') }}
                  </v-card-text>
                  <v-list v-else>
                    <v-list-item
                      v-for="(name, key) in like.name_form.options"
                      @mousedown="switchPersonDetails(name.person.id)"
                      :key="key"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ [name.sur, name.other].filter(Boolean).join(' ') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              <v-row>
                <v-col>
                  <InputDate
                    v-model="name_form.effect_date"
                    :label="$t('person.details.form_name.effect_date.label')"
                    data-cy="np_name_eff_date"
                    ref="name_effect_date"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    @click="() => {
                      // validate
                      $v.name_form.$touch();
                      if ($v.name_form.$anyError || !$refs.name_effect_date.correctDate)
                        return;

                      if (name_form.uuid) {
                        // edit name
                        form.names.splice(form.names.findIndex(({uuid}) => {
                          return uuid == name_form.uuid
                        }), 1, JSON.parse(JSON.stringify(name_form)));
                      }
                      else {
                        // add name
                        form.names.push({
                          ...JSON.parse(JSON.stringify(name_form)),
                          uuid: uuidv4() // default local-id override
                        });
                      }

                      // reset form
                      name_form = getNameForm(1,1)
                      $v.name_form.$reset();
                    }"
                    data-cy="np_name_save_btn"
                  >
                    {{ name_form.uuid ? $t('person.details.form_iden.buttons.update') : $t('person.details.form_iden.buttons.add') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="8">
              <v-simple-table
                fixed-header
                height="375px"
                data-cy="np_name_list_table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t('person.details.form_name.name_type.label') }}
                      </th>
                      <th class="text-left">
                        {{ $t('person.details.form_name.sur.label') }}
                      </th>
                      <th class="text-left">
                        {{ $t('person.details.form_name.other.label') }}
                      </th>
                      <th class="text-left">
                        {{ $t('person.details.form_name.effect_date.label') }}
                      </th>
                      <th class="text-left">
                        {{ $t('listing.table.action.label') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(name, key) in form.names"
                      :key="key"
                    >
                      <td>{{ $t(`person.details.form_name.titles.${(name.lang == 1 ? 'english' : 'chinese') + (name.name_type == 1 ? '' : '_alias')}`) }}</td>
                      <td
                        :data-cy="`np_surname_${key}`"
                      >
                        {{ name.sur }}
                      </td>
                      <td
                        :data-cy="`np_othername_${key}`"
                      >
                        {{ name.other }}
                      </td>
                      <td
                        :data-cy="`np_name_effdate_${key}`"
                      >
                        {{ name.effect_date }}
                      </td>
                      <td>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <!-- <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-undo</v-icon></v-btn> -->
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="() => {
                                Object.assign(name_form, name);
                              }"
                              :data-cy="`np_name_edit_btn_${key}`"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('person.details.form_iden.buttons.update') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                color="error"
                                @click="() => {
                                  // remove name
                                  form.names.splice(key, 1)
                                }"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('person.details.form_iden.buttons.remove') }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card-title
        data-cy="iden_form_title"
      >
        {{ $t('person.details.form_iden.title') }}
      </v-card-title>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              cols="4"
              data-cy="iden_type_col"
            >
              <!-- <v-select label="Type" v-model="iden_form.type" :items="[
                                { value: 0, text: 'Others' },
                                { value: 1, text: 'HKID' },
                                { value: 2, text: 'Passport' },
                            ]"></v-select> -->
              <v-select
                :label="$t('person.details.form_iden.type.label')"
                v-model="iden_form.type"
                :items="[
                  { value: 0, text: $t('person.details.form_iden.type.options[0]') },
                  { value: 1, text: $t('person.details.form_iden.type.options[1]') },
                  { value: 2, text: $t('person.details.form_iden.type.options[2]') },
                ]"
                data-cy="iden_type_select"
              >
                <template
                  v-slot:selection="items"
                >
                  <div
                    data-cy="selected_iden_type"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
                <template
                  v-slot:item="items"
                >
                  <div
                    :data-cy="`iden_type_${items.item.value}`"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="4"
              v-if="[0,1,2].indexOf(iden_form.type) != -1"
            >
              <v-menu
                auto
                :offset-y="true"
                :value="like.iden_form.is_show"
                :close-on-content-click="false"
                :close-on-click="false"
                :open-on-click="false"
              >
                <template v-slot:activator="{ attrs }">
                  <v-text-field
                    v-bind="{...attrs}"
                    :label="$t('person.details.form_iden.id_number.label')"
                    v-model="iden_form.id_number"
                    :error="$v.iden_form.id_number.$error"
                    :error-messages="$v.iden_form.id_number.$dirty?(($v.iden_form.id_number.$error) ? (!$v.iden_form.id_number.hkid_valid_format ? 'Please input valid HKID format (e.g A123456(7))' : (
                      (!$v.iden_form.id_number.hkid_checksum ? 'Please input valid HKID checkdigit' :
                        (!$v.iden_form.duplicate_db ? 'record is duplicated in database' :
                          (!$v.iden_form.duplicate_local ? 'record is duplicated' :
                            (!$v.iden_form.id_number.required ? $t('validate.required', { field: $t('person.details.form_iden.id_number.label') }) : '')))))) : '') : ''"
                    @focus="toggleSimilarDropdown(like.iden_form, true)"
                    @blur="toggleSimilarDropdown(like.iden_form, false)"
                    @change="() => {
                      $v.iden_form.$touch();
                    }"
                    data-cy="iden_number"
                  />
                </template>
                <v-card :loading="!!iden_similar_check_timeout">
                  <v-card-subtitle>{{ $t('person.details.form_iden.dropdown.title') }}</v-card-subtitle>
                  <v-card-text v-if="like.iden_form.options.length == 0">
                    {{ $t('person.details.form_iden.dropdown.no_records') }}
                  </v-card-text>
                  <v-list v-else>
                    <v-list-item
                      v-for="(iden, key) in like.iden_form.options"
                      @mousedown="switchPersonDetails(iden.parent.id)"
                      :key="key"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle>{{ iden.id_number }}</v-list-item-subtitle>
                        <v-list-item-title>{{ $t(`person.details.form_iden.type.options[${iden.type}]`) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
            <v-col
              cols="2"
              v-if="[0,2].indexOf(iden_form.type) != -1"
            >
              <v-text-field
                :label="$t('person.details.form_iden.id_number_security_num.label')"
                :error="$v.iden_form.id_number_security_num.$error"
                :error-messages="($v.iden_form.id_number_security_num.$dirty && !$v.iden_form.id_number_security_num.required) ? $t('validate.required', { field: $t('person.details.form_iden.id_number_security_num.label') }) : ''"
                v-model="iden_form.id_number_security_num"
                data-cy="iden_no_sec_number"
              />
            </v-col>
            <v-col
              cols="4"
              v-if="[0,2].indexOf(iden_form.type) != -1"
            >
              <InputDate
                :label="$t('person.details.form_iden.expiry_date.label')"
                :error="$v.iden_form.expiry_date.$error"
                :error-messages="$v.iden_form.expiry_date.$error ? $t('validate.required', { field: $t('person.details.form_iden.expiry_date.label') }) : ''"
                v-model="iden_form.expiry_date"
                title="Expiry Date"
                data-cy="iden_exp_date"
                ref="iden_expiry_date"
              />
            </v-col>
            <v-col
              cols="2"
              v-if="[0,2].indexOf(iden_form.type) != -1"
            >
              <v-text-field
                :label="$t('person.details.form_iden.expiry_date_security_num.label')"
                :error="$v.iden_form.expiry_date_security_num.$error"
                :error-messages="($v.iden_form.expiry_date_security_num.$dirty && !$v.iden_form.expiry_date_security_num.required) ? $t('validate.required', { field: $t('person.details.form_iden.expiry_date_security_num.label') }) : ''"
                v-model="iden_form.expiry_date_security_num"
                data-cy="iden_exp_sec_number"
              />
            </v-col>
            <v-col
              cols="3"
              v-if="[0,2].indexOf(iden_form.type) != -1"
            >
              <CountrySelector
                :label="$t('person.details.form_iden.country.label')"
                :error="$v.iden_form.country.$error"
                :error-messages="$v.iden_form.country.$error ? $t('validate.required', { field: $t('person.details.form_iden.country.label') }) : ''"
                v-model="iden_form.country"
                data-cy="iden_country_selector"
              />
            </v-col>
            <v-col :cols="iden_form.type == 1 ? 4: 3">
              <InputDate
                v-model="iden_form.effect_date"
                :label="$t('person.details.form_iden.effect_date.label')"
                data-cy="iden_eff_date"
                ref="iden_effect_date"
              />
            </v-col>
            <v-col cols="12">
              <v-btn-toggle dense>
                <v-menu
                  offset-y
                  v-if="(form.idens || []).filter(iden => !iden.is_deleted).length"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      data-cy="iden_edit_button"
                    >
                      <span v-if="iden_form.uuid">{{ $t('person.details.form_iden.buttons.edit', { value: iden_form.id_number }) }}</span>
                      <span v-else>
                        <span>{{ $t('person.details.form_iden.buttons.select') }}
                          <!-- <i class="fa fa-caret-down"></i> -->
                        </span>
                        (<span class="badge bg-aqua">{{ form.idens.filter(iden => !iden.is_deleted).length }}</span>)
                      </span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(iden, index) in form.idens.filter(iden => !iden.is_deleted)"
                      @click="iden_form = iden"
                      :key="index"
                    >
                      <v-list-item-title
                        :data-cy="`iden_${index}`"
                      >
                        <table>
                          <tr>
                            <td
                              class="col-xs-1"
                              style="vertical-align: top;"
                            >
                              #{{ index + 1 }}
                            </td>
                            <td class="col-xs-6">
                              <span>{{ iden.id_number }}</span>
                            </td>
                            <td
                              class="col-xs-1"
                              style="vertical-align: top;"
                            >
                              <span v-if="iden.effect_date"> [eff. <timeago
                                :datetime="iden.effect_date"
                                :auto-update="60"
                              />]</span>
                            </td>
                          </tr>
                        </table>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  :loading="$v.iden_form.$pending"
                  @click="
                    saveRow(form.idens, iden_form, () => {
                      $v.iden_form.$touch();
                      return $v.iden_form.$invalid && ($refs.iden_effect_date.correctDate && ([0,2].indexOf(iden_form.type) != -1? $refs.iden_expiry_date.correctDate : true))
                    }, () => {
                      iden_form = getIdenForm()
                      $v.iden_form.$reset();
                    })"
                  data-cy="iden_save_btn"
                >
                  {{ iden_form.uuid ? $t('person.details.form_iden.buttons.update') : $t('person.details.form_iden.buttons.add') }}
                </v-btn>
                <v-btn
                  @click="removeRow(form.idens, iden_form, () => { iden_form = getIdenForm() })"
                  v-if="iden_form.uuid"
                  data-cy="iden_remove_btn"
                >
                  {{ $t('person.details.form_iden.buttons.remove') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card-title
        data-cy="np_contact_info_title"
      >
        {{ $t('person.details.tabs.contact') }}
      </v-card-title>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card-subtitle class="px-0">
                {{ $t('person.details.form_address.title') }}
              </v-card-subtitle>
              <v-text-field
                :label="$t('person.details.form_address.address_1.label')"
                v-model="address_form.address_1"
                :error="$v.address_form.address_1.$error"
                :error-messages="$v.address_form.address_1.$error ? (!$v.address_form.address_1.required ? $t('validate.required', { field: $t('person.details.form_address.address_1.label') }) : '') : ''"
                data-cy="np_address_1"
              />
              <v-text-field
                :label="$t('person.details.form_address.address_2.label')"
                v-model="address_form.address_2"
                data-cy="np_address_2"
              />
              <v-text-field
                :label="$t('person.details.form_address.address_3.label')"
                v-model="address_form.address_3"
                data-cy="np_address_3"
              />
              <country-selector
                :label="$t('person.details.form_address.address_4.label')"
                v-model="address_form.address_4"
                :error="$v.address_form.address_4.$error"
                :error-messages="$v.address_form.address_4.$error ? (!$v.address_form.address_4.required ? $t('validate.required', { field: $t('person.details.form_address.address_4.label') }) : '') : ''"
                data-cy="np_address_4"
              />
              <InputDate
                v-model="address_form.effect_date"
                :label="$t('person.details.form_address.effect_date.label')"
                data-cy="np_address_eff_date"
                ref="address_effect_date"
              />
              <v-btn-toggle dense>
                <v-menu
                  offset-y
                  v-if="(form.addresses || []).filter(address => !address.is_deleted).length"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      data-cy="edit_np_address_btn"
                    >
                      <span v-if="address_form.uuid">{{ $t('person.details.form_address.buttons.edit', { value: address_form.address_1 }) }}</span>
                      <span v-else>
                        <span>{{ $t('person.details.form_address.buttons.select') }}
                          <!-- <i class="fa fa-caret-down"></i> -->
                        </span>
                        (<span class="badge bg-aqua">{{ form.addresses.filter(address => !address.is_deleted).length }}</span>)
                      </span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(address, index) in form.addresses.filter(address => !address.is_deleted)"
                      @click="address_form = address"
                      :key="index"
                    >
                      <v-list-item-title
                        :data-cy="`address_${index}`"
                      >
                        <table>
                          <tr>
                            <td
                              class="col-xs-1"
                              style="vertical-align: top;"
                            >
                              #{{ index + 1 }}
                            </td>
                            <td class="col-xs-6">
                              <div>{{ address.address_1 }}</div>
                            </td>
                            <td
                              class="col-xs-1"
                              style="vertical-align: top;"
                            >
                              <span v-if="address.effect_date"> [eff. <timeago
                                :datetime="address.effect_date"
                                :auto-update="60"
                              />]</span>
                            </td>
                          </tr>
                        </table>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  @click="$refs.address_effect_date.correctDate?
                    saveRow(form.addresses, address_form, () => {
                      $v.address_form.$touch();
                      return $v.address_form.$error;
                    }, () => {
                      address_form = getAddressForm(1)
                      $v.address_form.$reset();
                    }): null"
                  data-cy="save_np_address_btn"
                >
                  {{ address_form.uuid ? $t('person.details.form_address.buttons.update') : $t('person.details.form_address.buttons.add') }}
                </v-btn>
                <v-btn
                  @click="removeRow(form.addresses, address_form, () => { address_form = getAddressForm(1) })"
                  v-if="address_form.uuid"
                  data-cy="remove_np_address_btn"
                >
                  {{ $t('person.details.form_address.buttons.remove') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="6">
              <v-card-subtitle class="px-0">
                {{ $t('person.details.form_email.title') }}
              </v-card-subtitle>
              <v-text-field
                :label="$t('person.details.form_email.email.label')"
                v-model="email_form.email"
                :error="$v.email_form.email.$error"
                :error-messages="$v.email_form.email.$error ? (
                  !$v.email_form.email.required ? $t('validate.required', { field: $t('person.details.form_email.email.label') }) : (
                    !$v.email_form.email.email ? $t('validate.email') : '')) : ''"
                data-cy="np_email_textfield"
              />
              <InputDate
                v-model="email_form.effect_date"
                :label="$t('person.details.form_email.effect_date.label')"
                data-cy="np_email_eff_date"
                ref="email_effect_date"
              />
              <v-btn-toggle dense>
                <v-menu
                  offset-y
                  v-if="(form.emails || []).filter(email => !email.is_deleted).length"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      data-cy="edit_np_email_btn"
                    >
                      <span v-if="email_form.uuid">{{ $t('person.details.form_email.buttons.edit', { value: email_form.email }) }}</span>
                      <span v-else>
                        <span>{{ $t('person.details.form_email.buttons.select') }}
                          <!-- <i class="fa fa-caret-down"></i> -->
                        </span>
                        (<span class="badge bg-aqua">{{ form.emails.filter(email => !email.is_deleted).length }}</span>)
                      </span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(email, index) in form.emails.filter(email => !email.is_deleted)"
                      @click="email_form = email"
                      :key="index"
                    >
                      <v-list-item-title
                        :data-cy="`email_${index}`"
                      >
                        <table>
                          <tr>
                            <td
                              class="col-xs-1"
                              style="vertical-align: top;"
                            >
                              #{{ index + 1 }}
                            </td>
                            <td class="col-xs-6">
                              <span>{{ email.email }}</span>
                            </td>
                            <td
                              class="col-xs-1"
                              style="vertical-align: top;"
                            >
                              <span v-if="email.effect_date"> [eff. <timeago
                                :datetime="email.effect_date"
                                :auto-update="60"
                              />]</span>
                            </td>
                          </tr>
                        </table>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  @click="$refs.email_effect_date.correctDate?
                    saveRow(form.emails, email_form, () => {
                      $v.email_form.$touch();
                      return $v.email_form.$error;
                    }, () => {
                      email_form = getEmailForm(2)
                      $v.email_form.$reset();
                    }): null"
                  data-cy="save_np_email_btn"
                >
                  {{ email_form.uuid ? $t('person.details.form_email.buttons.update') : $t('person.details.form_email.buttons.add') }}
                </v-btn>
                <v-btn
                  @click="removeRow(form.emails, email_form, () => { email_form = getEmailForm(2) })"
                  v-if="email_form.uuid"
                  data-cy="remove_np_email_btn"
                >
                  {{ $t('person.details.form_email.buttons.remove') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-5" />
          <v-row>
            <v-col cols="6">
              <vue-tel-input-vuetify
                :label="$t('person.details.form.tel.label')"
                placeholder=""
                v-model="form.tel"
                :disabled-fetching-country="true"
                default-country="HK"
                :preferred-countries="['HK']"
                data-cy="np_tel"
              />
            </v-col>
            <v-col cols="6">
              <vue-tel-input-vuetify
                :label="$t('person.details.form.fax.label')"
                placeholder=""
                v-model="form.fax"
                :disabled-fetching-country="true"
                default-country="HK"
                :preferred-countries="['HK']"
                data-cy="np_fax"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-5" />
          <v-row>
            <v-col cols="6">
              <InputDate
                v-model="form.dob"
                :label="$t('person.details.form.dob.label')"
                data-cy="np_dob"
                ref="dob"
              />
            </v-col>
            <v-col cols="6">
              <country-selector
                :label="$t('person.details.form.pob.label')"
                v-model="form.pob"
                ref="pob"
                :hint="$t('person.details.form.pob.hint')"
                data-cy="np_pob"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              data-cy="gender_selection"
            >
              <v-select
                v-model="form.gender"
                menu-props="auto"
                :label="$t('person.details.form.gender.label')"
                :items="[
                  { value: '', text: $t('person.details.form.gender.options.empty') },
                  { value: 'Male', text: $t('person.details.form.gender.options.male') },
                  { value: 'Female', text: $t('person.details.form.gender.options.female') },
                  { value: 'Others', text: $t('person.details.form.gender.options.others') },
                ]"
              >
                <template
                  v-slot:selection="items"
                >
                  <div
                    data-cy="selected_gender"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
                <template
                  v-slot:item="items"
                >
                  <div
                    :data-cy="items.item.value"
                  >
                    {{ items.item.text }}
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import uuidv4 from 'uuid/v4'
import * as helper from '@/store/helper'
import Vuelidate from 'vuelidate'
import { required, requiredIf, email } from 'vuelidate/lib/validators'
import Vue from 'vue'
// extra inputs
import InputDate from '@/components/forms/InputDate'
import CountrySelector from '@/components/forms/CountrySelector'
Vue.use(Vuelidate)

export default {
  components: {
    InputDate,
    CountrySelector
  },
  watch: {
    $route (to, unusedFrom) {
      if (to.name === 'NaturalPersonEdit') {
        this.person_id = to.params.np_id
        // this.loadPersonDetail();
      } else if (to.name === 'NaturalPersonCreate') {
        this.person_id = 0
      }
    },
    name_form: {
      deep: true,
      handler (name_form) {
        // console.log(name_form)
        this.name_similar_check(name_form)
      }
    },
    'iden_form.id_number': {
      deep: true,
      handler (id_number) {
        this.iden_similar_check(id_number)
      }
    },
    'iden_form.type': function (type) {
      this.$v.iden_form.$reset()
      console.log('Change Type', type)
    }
  },
  data () {
    return {
      // columns: ['role', 'name', 'action'],
      // options: {
      // 	filterable: true,
      // },
      person_id: 0,
      requestLoading: false,
      form: helper.initialPerson(),
      dialog_delete_confirmation: false,
      dialog_delete_confirmation_loading: false,
      //
      name_form: helper.getNameForm(1, 1),
      iden_form: helper.getIdenForm(),
      address_form: helper.getAddressForm(1),
      email_form: helper.getEmailForm(2),
      like: {
        name_form: {
          options: [],
          is_show: false
        },
        iden_form: {
          options: [],
          is_show: false
        }
      },
      name_similar_check_timeout: null,
      iden_similar_check_timeout: null
    }
  },
  validations () {
    return {
      name_form: {
        sur: {
          required: requiredIf(() => !this.name_form.other)
        },
        other: {
          required: requiredIf(() => !this.name_form.sur)
        },
        duplicate_local (name) {
          return new Promise((resolve) => {
            var duplicate = this.form.names.filter((_name) => {
              return !_name.is_deleted &&
                                _name.other === name.other &&
                                _name.sur === name.sur &&
                                _name.lang === name.lang &&
                                _name.name_type === name.name_type &&
                                _name.uuid !== name.uuid
            })
            resolve(!(duplicate && duplicate.length > 0))
          })
        }
      },
      form: {
        names: {
          at_least_one_name (names) {
            return (names || []).filter((name) => { return !name.is_deleted }).length !== 0
          }
        }
      },
      iden_form: {
        id_number: {
          required,
          // iden_checksum (security_num) {
          //     return true;

          //     if (security_num === '' || this.iden_form.expiry_date === '' || this.iden_form.type !== 2) {
          //         return true;
          //     }

          //     return new Promise((resolve, reject) => {
          //         const FILLER = '<'
          //         const check = [7,3,1]

          //         let sum = 0;
          //         var date = this.iden_form.expiry_date instanceof Date ? this.iden_form.expiry_date : new Date(this.iden_form.expiry_date),
          //             day = String(date.getDate() || 0).padStart(2, "0"),
          //             month = String(date.getMonth() + 1 || 0).padStart(2, "0"),
          //             year = String(date.getFullYear() % 100 || 0).padStart(2, "0"),
          //             input = year + month + day;

          //         for (let i = 0; i < input.length; i++) {

          //             let val = parseInt(input[i]);

          //             if (Number.isNaN(val)) {
          //                 if (input[i] == FILLER) {
          //                     val = 0;
          //                 } else {
          //                     val = input[i].toUpperCase().charCodeAt(0) - 55; //A starts as 10
          //                 }
          //             }
          //             sum += val * check[i % 3];
          //         }

          //         return resolve(sum % 10 == security_num);
          //     })
          // },
          hkid_valid_format (hkid_num) {
            if (hkid_num === '' || this.iden_form.type !== 1) return true

            return new Promise((resolve) => {
              // var match = hkid_num.match(/^[A-Za-z]+\d{6}\(?\d{1}\)?$/);
              // resolve(match && match.length)
              var match = hkid_num.toUpperCase().match(/([A-Z]{1,2})(\d)(\d)(\d)(\d)(\d)(\d)\(?([\dA])\)?/)
              resolve(match && match.length)
            })
          },
          hkid_checksum (id_number) {
            if (id_number === '' || this.iden_form.type !== 1) return true

            return new Promise((resolve) => {
              try {
                var hkids = id_number.toUpperCase().match(/([A-Z]{1,2})(\d)(\d)(\d)(\d)(\d)(\d)\(?([\dA])\)?/)
                var valid
                if (hkids) {
                  var sum = 0
                  sum = sum + 2 * hkids[7]
                  sum = sum + 3 * hkids[6]
                  sum = sum + 4 * hkids[5]
                  sum = sum + 5 * hkids[4]
                  sum = sum + 6 * hkids[3]
                  sum = sum + 7 * hkids[2]
                  sum = sum + 8 * (hkids[1].charCodeAt(hkids[1].length === 2 ? 1 : 0) - 55)
                  sum = sum + 9 * (hkids[1].length === 2 ? (hkids[1].charCodeAt(0) - 55) : 58)
                  var checkdigit = sum % 11
                  valid = '0A987654321'.indexOf(hkids[8]) === checkdigit
                } else {
                  valid = false
                }

                console.log(hkids, sum, checkdigit, valid)
                resolve(valid)
              } catch (unusedError) {
                resolve(false)
              }
            })
          }
        },
        id_number_security_num: {
          required: requiredIf(() => this.iden_form.type === 2)
        },
        expiry_date: {
          required: requiredIf(() => this.iden_form.type === 2)
        },
        expiry_date_security_num: {
          required: requiredIf(() => this.iden_form.type === 2)
        },
        country: {
          required: requiredIf(() => this.iden_form.type === 2 || this.iden_form.type === 0)
        },
        duplicate_local (iden_form) {
          return new Promise((resolve) => {
            var duplicate = this.form.idens.filter((_iden) => {
              return !_iden.is_deleted &&
                                _iden.type === iden_form.type &&
                                _iden.id_number === iden_form.id_number &&
                                _iden.id_number_security_num === iden_form.id_number_security_num &&
                                (_iden.expiry_date || 0) === (iden_form.expiry_date || 0) &&
                                _iden.expiry_date_security_num === iden_form.expiry_date_security_num &&
                                _iden.country === iden_form.country &&
                                (_iden.effect_date || 0) === (iden_form.effect_date || 0) &&
                                _iden.uuid !== iden_form.uuid
            })
            resolve(!(duplicate && duplicate.length > 0))
          })
        },
        duplicate_db (iden_form) {
          if (iden_form.id_number === '') { return true }

          return new Promise((resolve) => {
            // console.log(id_number, this.iden_form.type, this.form.id);
            this.$store.dispatch('natural_person/similarIdentity', {
              person_id: this.form.id,
              id_type: iden_form.type,
              id_number: iden_form.id_number
            })
              .then((results) => {
                if (results.status === 200) {
                  // console.log(results);
                  // this.like.iden_form.options = results.data;
                  return resolve(results.data.filter(x => x.person != null).length === 0)
                }
                resolve(true)
              }).catch((unusedError) => {
                resolve(false)
              })
          })
        }
        // effect_date: null,
        // create_date: null,
        // updated_date: null,
        // is_deleted: false,
      },
      address_form: {
        address_1: {
          required
        },
        // address_2: {
        //   required
        // },
        // address_3: {
        //   required
        // },
        address_4: {
          required
        }
      },
      email_form: {
        email: {
          required,
          email
        }
      }
    }
  },
  methods: {
    uuidv4,
    ...helper,
    onSave (saved_person) {
      // var person = JSON.parse(JSON.stringify(this.form2));

      // // console.log('onSave', this, person)
      if (!this.person_id) {
        return this.$router.replace({
          name: 'NaturalPersonEdit',
          params: {
            np_id: saved_person.id
          }
        })
      }

      this.form = saved_person
      this.person_id = saved_person.id
    },
    onSwitchToID (person_id) {
      // this.person_id = person_id;
      this.$router.push({
        name: 'NaturalPersonEdit',
        params: {
          np_id: person_id
        }
      })
    },
    toggleSimilarDropdown (field, is_show) {
      clearTimeout(field.timeout)
      field.timeout = setTimeout(() => {
        field.is_show = is_show
      }, 100)
    },
    loadPersonDetail () {
      this.$v.$reset()
      this.form = helper.initialPerson()
      if (this.person_id) {
        this.requestLoading = true
        this.$store
          .dispatch('natural_person/get', this.person_id)
          .then((results) => {
            if (results.status === 200) {
              Object.assign(this.form, results.data)
              this.$notify({
                type: 'success',
                title: this.$t('person.details.msg.load.title'), // 'Load Natural Person Detail',
                text: this.$t('person.details.msg.load.success') // 'Successfully Loaded',
              })
              this.prepareFormData()
            } else {
              this.$notify({
                type: 'error',
                title: this.$t('person.details.msg.load.title'), // 'Load Natural Person Detail',
                text: this.$t('person.details.msg.load.success') // 'Load Detail Failed',
              })
            }

            this.requestLoading = false
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    prepareFormData () {
      // Reset All Form
      this.name_form = this.getNameForm(1, 1)
      // this.hkid_form = this.getHKIDForm();
      this.iden_form = this.getIdenForm()
      this.address_form = this.getAddressForm(1)
      this.email_form = this.getEmailForm(2)

      // Reset All Validation
      this.$v.name_form.$reset()
      // this.$v.hkid_form.$reset();
      this.$v.iden_form.$reset()
      this.$v.address_form.$reset()
      this.$v.email_form.$reset()

      // Reset All Similar Options
      this.like.name_form.options.splice(0)
      this.like.name_form.is_show = false
      this.like.iden_form.options.splice(0)
      this.like.iden_form.is_show = false

      // Setup Temp UUID
      this.form.names.map((name) => {
        name.uuid = uuidv4()
        return name
      })

      this.form.emails.map((email) => {
        email.uuid = uuidv4()
        return email
      })

      this.form.addresses.map((address) => {
        address.uuid = uuidv4()
        return address
      })

      this.form.hkids.map((hkid) => {
        hkid.uuid = uuidv4()
        return hkid
      })

      this.form.idens.map((iden) => {
        iden.uuid = uuidv4()
        return iden
      })
    },
    saveRow (form_field, row_for_save, callback_validation, callback_after_save) {
      // Callback
      if (callback_validation && typeof callback_validation === 'function') {
        if (callback_validation(form_field, row_for_save)) {
          return
        }
      }
      // Save Row
      if (row_for_save.uuid) {
        // Update Row
        var update_index = form_field.findIndex((row) => {
          return row.uuid === row_for_save.uuid
        })
        if (update_index !== -1) {
          form_field[update_index] = row_for_save
        }
      } else {
        // Insert Row
        row_for_save.uuid = uuidv4()
        form_field.push(row_for_save)
      }
      // Callback
      if (callback_after_save && typeof callback_after_save === 'function') {
        callback_after_save(form_field, row_for_save)
      }
    },
    removeRow (form_field, row_for_remove, callback_after_remove) {
      // Remove Row
      // var remove_index = form_field.findIndex((row) => {
      // 		return row.uuid == row_for_remove.uuid;
      // 	});
      // if (remove_index != -1) {
      // 	form_field.splice(remove_index, 1);
      // }
      row_for_remove.is_deleted = true
      // Callback
      if (callback_after_remove && typeof callback_after_remove === 'function') {
        callback_after_remove(form_field, row_for_remove)
      }
    },
    createPerson () {
      this.$v.form.$reset()
      if (this.$refs.dob.correctDate) {
        this.$nextTick(() => {
          this.$v.form.$touch()

          if (this.$v.form.$invalid) {
            this.$refs.form.scrollTop = 0
            return this.$refs.form.scrollTop
            // return this.$notify({
            //     type: 'error',
            //     title: this.$t('person.details.msg.valid.title'), //'Validate Person Detail',
            //     text: this.$t('person.details.msg.valid.error') //'Person detail is invalid'
            // });
          } else {
            // this.$notify({
            //     type: 'success',
            //     title: this.$t('person.details.msg.valid.title'), //'Validate Person Detail',
            //     text: this.$t('person.details.msg.valid.success') //'Person detail is valid'
            // });
          }

          return this.$store.dispatch('natural_person/save', this.form)
            .then(results => {
              if (results.status === 200) {
                // this.form = results.data;
                // this.prepareFormData();
                // this.VB2form(results.data);
                this.$notify({
                  type: 'success',
                  title: this.$t('person.details.msg.save.title'), // 'Save Natural Person',
                  text: this.$t('person.details.msg.save.success') // 'Successfully Saved',
                })
                // this.$emit('onSave', this.form);

                this.$router.push({ name: 'NaturalPersonEdit', params: { np_id: results.data.id } })
              } else {
                this.$notify({
                  type: 'error',
                  title: this.$t('person.details.msg.save.title'), // 'Save Natural Person',
                  text: this.$t('person.details.msg.save.error') // 'Save Failed',
                })
              }
            },
            (error) => {
              this.$notify({
                type: 'error',
                title: this.$t('person.details.msg.save.title'),
                text: (
                  error.response && error.response.data && error.response.data.code === 'E_UNIQUE'
                    ? this.$t('person.details.msg.save.error_unique')
                    : this.$t('person.details.msg.save.exception')
                )
              })
            })
        })
      }
    },
    switchPersonDetails (person_id) {
      // console.log('switchCompanyDetails', company_id)
      this.like.name_form.options = []
      this.like.iden_form.options = []
      this.onSwitchToID(person_id)
    },
    name_similar_check (name) {
      clearTimeout(this.name_similar_check_timeout)
      this.name_similar_check_timeout = null

      if (!name.sur && !name.other) { return true }

      this.name_similar_check_timeout = setTimeout(() => {
        this.$store.dispatch('natural_person/similarName', {
          person_id: this.form.id,
          lang: name.lang,
          name_type: name.name_type,
          sur: name.sur,
          other: name.other
        })
          .then((results) => {
            if (results.data) { this.like.name_form.options = results.data.filter(x => x.person != null) }
          }).finally(() => {
            this.name_similar_check_timeout = null
          })
      }, 500)
    },
    iden_similar_check (id_number) {
      clearTimeout(this.iden_similar_check_timeout)
      this.iden_similar_check_timeout = null

      if (id_number === '') { return true }

      this.iden_similar_check_timeout = setTimeout(() => {
        this.$store.dispatch('natural_person/similarIdentity', {
          person_id: this.form.id,
          id_type: this.iden_form.type,
          id_number: id_number
        })
          .then((results) => {
            if (results.data) { this.like.iden_form.options = results.data.filter(x => x.person != null) }
          }).catch((unusedError) => {

          }).finally(() => {
            this.iden_similar_check_timeout = null
          })
      }, 500)
    }
  },
  created () {
    // console.log('CustomerInfor', this)
    if (this.$route.params.np_id) {
      this.person_id = this.$route.params.np_id
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* button.btn-sm.btn-block {
	width: 65px;
	float: left;
	margin: 0 5px;
}
.v--modal-overlay {
	z-index: 9999;
} */
/* .box.box-primary {
	max-height: calc(100vh - 51px - 149px);
    overflow-y: auto;
    overflow-x: hidden;
} */
/* .btn-primary {
	margin: auto 3px;
} */
/* @media (max-width: 767px) {
	.box.box-primary {
		max-height: calc(100vh - 51px - 149px - 50px);
	}
} */
/* .wizard-header {
	display: none;
} */
</style>
